import "./style.css";
/*******************/
/*Fonction de copie*/
/******************/

const conteneurCopie = document.querySelector("#jf_conteneur-copie");
conteneurCopie.addEventListener("click", event => {
  const btnCopieTexte = "jf_btn-copie";
  const btnCopieLien = "jf_btn-lien-copie";
  let idBtnCp = event.target.getAttribute("class");
  if (idBtnCp) {
    if (idBtnCp.includes(btnCopieTexte)) {
      const index = idBtnCp.indexOf(btnCopieTexte);
      idBtnCp = idBtnCp.slice(index, index + btnCopieTexte.length);
    } else if (idBtnCp.includes(btnCopieLien)) {
      const index = idBtnCp.indexOf(btnCopieLien);
      idBtnCp = idBtnCp.slice(index, index + btnCopieLien.length);
    }
    if (idBtnCp != "jf_btn-copie" && idBtnCp != "jf_btn-lien-copie") {
      return null;
    }
  }
  const contenantCopie = event.target.closest(".jf_contenu-copie");

  function copie() {
    const cibleCopie = jf_trouverCible(contenantCopie, idBtnCp);
    let btn = jf_trouverBtn(contenantCopie, idBtnCp);
    if (btn === "") {
      btn = jf_trouverBtn_2(contenantCopie, idBtnCp);
    }
    if (cibleCopie.localName === "a") {
      /* En raison de l'incompatibilité de l'api clipboard avec Safari,
    on place le lien dans une div qui pourra être sélectionné par getSelection.
    Pour qu'elle ne soit pas visible, on applique en css opacity: 0;
    position: absolute; z-index: -1; car ne fontionne pas avec display:none */
      const div = document.querySelector("#jf_copie-lien");
      div.innerText = cibleCopie.href;
      jf_fnCopie(div);
    } else {
      jf_fnCopie(cibleCopie);
    }
    btn.innerText = "Copied!";
    btn.classList.add("btn-success");
  }
  //   retourne null si contenantCopie n'est pas défini, c'est-à-dire que le clic n'a pas eu lieu sur un bouton défini
  return contenantCopie ? copie() : null;
});

/*********************/

// Fonction de copie
const jf_fnCopie = cible => {
  const range = document.createRange();
  range.selectNodeContents(cible);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  console.log(range);
  document.execCommand("copy");
  // window.getSelection().removeAllRanges();
  // jf_afficherModal(conteneurElemCopie);
};
// Fonction de recherche de la cible de la copie
function jf_trouverCible(noeudParent, idBtnCp) {
  if (noeudParent.hasChildNodes()) {
    //
    const enfants = noeudParent.childNodes;
    const id = (() => {
      if (idBtnCp === "jf_btn-copie") {
        return "jf_cible-copie";
      } else if (idBtnCp === "jf_btn-lien-copie") {
        return "jf_lien-cible-copie";
      } else {
        new console.error("cible inconnue");
      }
    })();

    //
    for (let i = 0; i < enfants.length; i++) {
      if (enfants[i].classList) {
        const id1 = (() => {
          if (enfants[i].classList.contains(id) && id === "jf_cible-copie") {
            return "texte";
          } else if (
            enfants[i].classList.contains(id) &&
            id === "jf_lien-cible-copie"
          ) {
            return "lien";
          } else return "";
        })();
        if (id === "jf_cible-copie" && id1 === "texte") {
          return enfants[i];
        }
        // Le lien n'est pas un enfant direct du conteneur
        // cela nécessite une étape supplémentaire
        if (id === "jf_lien-cible-copie" && id1 === "lien") {
          const n = enfants[i].childNodes;
          for (let i = 0; i < n.length; i++) {
            if (n[i].localName === "a") {
              return n[i];
            }
          }
        }
      }
    }
  }
}
// Fonction de recherche du bouton cliqué
function jf_trouverBtn(noeudParent, idBtnCp) {
  if (noeudParent.hasChildNodes()) {
    const enfants = noeudParent.childNodes;
    const id = idBtnCp;
    for (let i = 0; i < enfants.length; i++) {
      if (enfants[i].classList) {
        const id1 = (() => {
          if (enfants[i].classList.contains(id) && id === "jf_btn-copie") {
            return "texte";
          } else if (
            enfants[i].classList.contains(id) &&
            id === "jf_btn-lien-copie"
          ) {
            return "lien";
          } else return "";
        })();
        if (id === "jf_btn-copie" && id1 === "texte") {
          return enfants[i];
        } else if (id === "jf_btn-lien-copie" && id1 === "lien") {
          return enfants[i];
        }
      }
    }
  }
  // retour si pas trouvé
  console.log("Pas trouvé!!!");
  return "";
}
function jf_trouverBtn_2(noeudParent, idBtnCp) {
  if (noeudParent.hasChildNodes()) {
    const enfants = noeudParent.childNodes;
    const id = idBtnCp;
    let n;
    for (let i = 0; i < enfants.length; i++) {
      if (enfants[i].classList) {
        if (enfants[i].classList.contains("jf_btn-copie-conteneur")) {
          n = enfants[i].childNodes;
        }
      }
    }
    if (n) {
      for (let i = 0; i < n.length; i++) {
        console.log("trouvé");
        if (n[i].classList) {
          const id1 = (() => {
            if (n[i].classList.contains(id) && id === "jf_btn-copie") {
              return "texte";
            } else if (
              n[i].classList.contains(id) &&
              id === "jf_btn-lien-copie"
            ) {
              return "lien";
            } else return "";
          })();
          if (id === "jf_btn-copie" && id1 === "texte") {
            return n[i];
          } else if (id === "jf_btn-lien-copie" && id1 === "lien") {
            return n[i];
          }
        }
      }
    }
  }
}
